<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 40 40"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-71 -2646) translate(13 2581) translate(58 65)">
        <path
          :fill="color"
          fill-rule="nonzero"
          d="M10.469 20.776c0-.864.7-1.564 1.562-1.564h7.188V8.27c0-.863.7-1.563 1.562-1.563.863 0 1.563.7 1.563 1.563v12.507c0 .863-.7 1.563-1.563 1.563h-8.75c-.863 0-1.562-.7-1.562-1.563zm28.878 4.665c-.702-.503-1.678-.341-2.18.36l-7.702 10.764c-.21.244-.474.297-.615.306-.145.01-.424-.009-.67-.24l-4.976-4.859c-.618-.603-1.607-.59-2.21.027-.602.618-.59 1.608.027 2.211l4.986 4.868.014.014c.747.712 1.745 1.108 2.772 1.108.088 0 .177-.003.265-.01 1.118-.074 2.162-.618 2.865-1.492l.052-.07 7.733-10.806c.502-.702.34-1.679-.361-2.181zM19.643 36.874c-9.108-.189-16.518-7.761-16.518-16.88 0-4.21 1.576-8.25 4.375-11.34v3.21c0 .864.7 1.564 1.562 1.564.864 0 1.563-.7 1.563-1.563V8.269c0-2.586-2.103-4.69-4.688-4.69H2.344C1.48 3.58.78 4.28.78 5.143c0 .863.7 1.563 1.563 1.563h2.705C1.817 10.348 0 15.072 0 19.994c0 5.271 2.031 10.247 5.72 14.01C9.403 37.76 14.324 39.89 19.578 40h.033c.848 0 1.544-.679 1.561-1.53.018-.864-.667-1.578-1.53-1.596zm1.073-33.749c9.061.378 16.159 7.788 16.159 16.869 0 .238-.005.48-.015.718-.036.862.634 1.59 1.496 1.627h.066c.833 0 1.525-.657 1.56-1.497.012-.281.018-.566.018-.848 0-5.197-1.982-10.122-5.582-13.868C30.83 2.392 26.01.216 20.846.001c-.862-.037-1.59.635-1.626 1.497-.036.863.634 1.591 1.496 1.627z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 40
    },
    variant: {
      type: String,
      default: 'light' // light, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'light') {
        return '#e5e5e5'
      }

      // Default: light
      return '#e5e5e5'
    })()
    
    return {
      color,
    }
  }
})
</script>
