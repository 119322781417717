<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="9"
    viewBox="0 0 11 9"
  >
    <defs>
      <filter id="4e6wqv8sqa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.670588 0 0 0 0 0.670588 0 0 0 0 0.670588 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g
          filter="url(#4e6wqv8sqa)"
          transform="translate(-387 -2600) translate(13 2581)"
        >
          <g fill="#C7C7C7">
            <path
              d="M.92 3.801l9.195-.204c.423-.01.774.326.783.749v.034c-.01.448-.37.809-.818.819l-9.195.204c-.423.01-.774-.326-.783-.749V4.62c.01-.448.37-.809.818-.819z"
              transform="translate(374 19) rotate(-44 5.5 4.5)"
            />
            <path
              d="M.92 3.801l9.195-.204c.423-.01.774.326.783.749v.034c-.01.448-.37.809-.818.819l-9.195.204c-.423.01-.774-.326-.783-.749V4.62c.01-.448.37-.809.818-.819z"
              transform="translate(374 19) scale(-1 1) rotate(-44 0 18.113)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
