<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40">
    <defs>
      <filter id="zbfcs5lnwa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.780392 0 0 0 0 0.780392 0 0 0 0 0.780392 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g filter="url(#zbfcs5lnwa)" transform="translate(-912 -1208)">
        <g fill="#000" fill-rule="nonzero">
          <path
            d="M9.375 15.86H6.288C6.809 8.75 12.76 3.124 20 3.124c5.73 0 10.913 3.609 12.9 8.98.3.809 1.199 1.222 2.008.923.81-.3 1.223-1.198.923-2.008-1.184-3.2-3.288-5.94-6.084-7.922C26.887 1.071 23.517 0 20 0c-4.507 0-8.745 1.755-11.932 4.943-3.146 3.145-4.896 7.314-4.94 11.756C1.26 17.781 0 19.8 0 22.109v5.313c0 3.446 2.804 6.25 6.25 6.25h3.125c.863 0 1.563-.7 1.563-1.563V17.422c0-.863-.7-1.563-1.563-1.563zM7.812 30.546H6.25c-1.723 0-3.125-1.402-3.125-3.125v-5.313c0-1.723 1.402-3.125 3.125-3.125h1.563v11.563z"
            transform="translate(912 1208)"
          />
          <path
            d="M33.75 15.86h-3.125c-.863 0-1.563.699-1.563 1.562V33.75c0 .862-.7 1.563-1.562 1.563h-4.766v.049c-.533-.961-1.557-1.612-2.734-1.612-1.726 0-3.125 1.4-3.125 3.125 0 1.726 1.4 3.125 3.125 3.125 1.177 0 2.201-.65 2.734-1.612v.05H27.5c2.585 0 4.688-2.103 4.688-4.688v-.078h1.562c3.446 0 6.25-2.804 6.25-6.25v-5.313c0-3.446-2.804-6.25-6.25-6.25zm3.125 11.562c0 1.723-1.402 3.125-3.125 3.125h-1.563V18.985h1.563c1.723 0 3.125 1.401 3.125 3.124v5.313z"
            transform="translate(912 1208)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
