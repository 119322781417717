<template>
  <div
    class="benefits-card"
  >
    <h2
      v-if="isTitleDisplayed"
      class="benefits-card__title"
    >
      <DynLang by-key="unique-benefits" />
    </h2>
    <div>
      <div class="benefits-card__body">
        <div
          v-for="(benefit, index) in benefitList"
          :key="index"
          class="benefits-card__item"
        >
          <div class="benefits-card__item__content">
            <div class="benefits-card__item__title">
              <i class="benefits-card__item__icon">
                <component :is="benefit.icon" />
              </i>
              <DynLang
                :by-key="benefit.title"
                :default-label="benefit.title"
                capitalize-first
              />
            </div>
            <div class="benefits-card__item__description">
              <DynLang
                :by-key="benefit.description"
                :default-label="benefit.description"
                capitalize-first
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import GivingIcon from '../shared/icons/Giving.icon.vue'
import ShieldIcon from '../shared/icons/Shield.icon.vue'
import TrustIcon from '../shared/icons/Trust.icon.vue'
import WowIcon from '../shared/icons/Wow.icon.vue'
import ClockIcon from '../shared/icons/Clock.icon.vue'
import TicketIcon from '../shared/icons/Ticket.icon.vue'
import HeadPhonesIcon from '../shared/icons/HeadPhones.icon.vue'
import SmallTimesIcon from '../shared/icons/SmallTimes.icon.vue'

export default defineNuxtComponent({
  components: {
    GivingIcon,
    ShieldIcon,
    TrustIcon,
    WowIcon,
    TicketIcon,
    HeadPhonesIcon,
    SmallTimesIcon,
  },
  props: {
    tags: {
      type: Array as PropType<string[]>,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
    isTitleDisplayed: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const defaultBenefitList = [
      {
        tag: 'GIVINGS',
        title: 'benefits-givings',
        icon: GivingIcon
      },
      {
        tag: 'GIVINGS-SMALL',
        title: 'benefits-givings-small',
        icon: GivingIcon
      },
      {
        tag: 'BEST_PRICE',
        title: 'benefits-best-price',
        icon: ShieldIcon
      },
      {
        tag: 'SSL',
        title: 'benefits-ssl',
        icon: ShieldIcon
      },
      {
        tag: 'SAFE_PAYMENT',
        title: 'benefits-safe-payment',
        icon: TrustIcon
      },
      {
        tag: 'SAFE_PAYMENT_ENSURED',
        title: 'benefits-safe-payment-ensured',
        icon: TrustIcon
      },
      {
        tag: 'SAVE_TIME',
        title: 'benefits-save-time',
        description: 'benefits-desc-save-time',
        icon: ClockIcon,
      },
      {
        tag: 'GIVE_EXPERIENCIES',
        title: 'benefits-give-experiencies',
        description: 'benefits-desc-give-experiencies',
        icon: WowIcon,
      },
      {
        tag: 'VALID_BONUSES',
        title: 'benefits-valid-bonuses',
        description: 'benefits-desc-valid-bonuses',
        icon: TicketIcon,
      },
      {
        tag: 'SUPPORT',
        title: 'benefits-support',
        description: 'benefits-desc-support',
        icon: HeadPhonesIcon,
      },
    ]

    const benefitList = props.tags.map((tag) => {
      for (const bene of defaultBenefitList) {
        if (bene.tag === tag) {
          return bene
        }
      }

      return {
        tag: tag,
        title: tag,
        description: tag,
        icon: WowIcon,
      }
    })

    const isSingle = benefitList.length === 1

    return {
      benefitList,
      isSingle,
    }
  },
})
</script>

<style lang="scss" scoped>
.benefits-card {
  @apply my-4;

  &__body {
    @apply py-4;

    @apply flex;

    @apply flex-col;
    @apply justify-center;

    @apply gap-2;

    @screen lg {
      @apply flex-row;
    }
  }

  &__title {
    @apply text-spl-dark;
    @apply font-bold;
    @apply text-xl;

    @apply mb-2;
  }

  &__item {
    @apply basis-0;
    @apply flex-grow;

    @apply flex flex-col justify-start items-center;
    @apply gap-2;
    @apply py-2;

    @screen lg {
      @apply w-full;
    }

    &__title {
      @apply flex;
      @apply justify-center items-center;
      @apply flex-col;
      @apply gap-2;

      @apply uppercase font-bold;
      @apply text-center;

      @screen md {
        @apply flex-row;
        @apply text-left;
      }
    }

    &__content {
      @apply w-full;
    }

    &__description {
      @apply hidden;

      @apply text-spl-dark;
      @apply text-center;
      @apply font-bold text-base;
      @apply whitespace-pre-wrap;
      @apply mx-auto my-15p;
      @screen md {
        @apply block;
        @apply max-w-250p;
      }
    }
  }
}
</style>
