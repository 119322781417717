<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37" viewBox="0 0 40 37">
    <defs>
      <filter id="my5uxr3eia">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.780392 0 0 0 0 0.780392 0 0 0 0 0.780392 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g filter="url(#my5uxr3eia)" transform="translate(-977 -2401)">
        <g>
          <path
            fill="#000"
            fill-rule="nonzero"
            d="M38.748 20.5c.729-.148 1.252-.788 1.252-1.531v-3.985c0-3.446-2.804-6.25-6.25-6.25H6.25c-.452 0-.893.05-1.318.141.2-.092.412-.164.635-.214l24.432-5.475c1.268-.274 2.569.385 3.092 1.57.35.79 1.272 1.146 2.061.798.79-.349 1.147-1.271.798-2.06C34.828.955 32.044-.458 29.33.133c-.003 0-.006 0-.009.002L4.883 5.612C3.503 5.92 2.256 6.7 1.371 7.804.487 8.908 0 10.296 0 11.711v7.258c0 .743.523 1.383 1.252 1.53 1.085.221 1.873 1.187 1.873 2.298 0 1.11-.788 2.076-1.873 2.296C.523 25.241 0 25.882 0 26.625v3.984c0 3.446 2.804 6.25 6.25 6.25h27.5c3.446 0 6.25-2.804 6.25-6.25v-3.984c0-.743-.523-1.384-1.252-1.532-1.085-.22-1.873-1.186-1.873-2.296 0-1.11.788-2.077 1.873-2.297zm-1.873-2.645c-1.868.89-3.125 2.8-3.125 4.942 0 2.14 1.257 4.051 3.125 4.941v2.871c0 1.723-1.402 3.125-3.125 3.125h-3.828c0-.863-.7-1.562-1.563-1.562s-1.562.7-1.562 1.562H6.25c-1.723 0-3.125-1.402-3.125-3.125v-2.871c1.868-.89 3.125-2.8 3.125-4.941s-1.257-4.052-3.125-4.942v-2.87c0-1.724 1.402-3.126 3.125-3.126h20.547c0 .863.7 1.563 1.562 1.563.863 0 1.563-.7 1.563-1.563h3.828c1.723 0 3.125 1.402 3.125 3.125v2.871zm-6.953-.527c0 .863-.7 1.562-1.563 1.562s-1.562-.7-1.562-1.562c0-.863.7-1.563 1.562-1.563.863 0 1.563.7 1.563 1.563zm0 5.469c0 .863-.7 1.562-1.563 1.562s-1.562-.7-1.562-1.562c0-.863.7-1.563 1.562-1.563.863 0 1.563.7 1.563 1.563zm0 5.468c0 .863-.7 1.563-1.563 1.563s-1.562-.7-1.562-1.563.7-1.562 1.562-1.562c.863 0 1.563.7 1.563 1.562zm-7.88-5.737l-2.166 1.556c-.322.232-.456.642-.333 1.017l.827 2.517c.275.838-.694 1.534-1.415 1.017l-2.165-1.557c-.161-.115-.35-.173-.54-.173-.19 0-.379.058-.54.173l-2.165 1.557c-.72.517-1.69-.18-1.415-1.017l.827-2.517c.123-.375-.011-.785-.333-1.017l-2.165-1.556c-.72-.517-.35-1.684.54-1.684h2.676c.398 0 .751-.214.874-.589l.827-2.533c.138-.42.506-.628.874-.628s.737.209.874.628l.827 2.533c.123.375.476.589.874.589h2.676c.89 0 1.26 1.167.54 1.684z"
            transform="translate(977 2401)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
