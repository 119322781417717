<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="46" viewBox="0 0 40 46" class="icon">
    <defs>
      <filter id="rgr8cknzqa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.780587 0 0 0 0 0.780587 0 0 0 0 0.780587 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g filter="url(#rgr8cknzqa)" transform="translate(-639 -1489)">
        <g fill="#000" fill-rule="nonzero">
          <path
            d="M38.902 8.262L20.634.153c-.462-.205-.99-.204-1.452.003l-18.09 8.11C.452 8.551.04 9.188.04 9.89v14.08c0 5.321 2.103 10.76 5.77 14.923 1.843 2.094 3.97 3.751 6.318 4.925 2.516 1.258 5.165 1.895 7.873 1.895 3.792 0 7.482-1.227 10.673-3.549.796-.58.971-1.694.392-2.49-.58-.796-1.694-.971-2.49-.392-2.577 1.875-5.542 2.867-8.575 2.867-8.581 0-16.396-8.665-16.396-18.179V11.046l16.31-7.312 16.483 7.316v12.921c0 3.282-.943 6.603-2.727 9.605-.503.846-.224 1.94.622 2.442.846.503 1.94.225 2.442-.621 2.111-3.553 3.227-7.503 3.227-11.426V9.891c0-.704-.415-1.343-1.06-1.629z"
            transform="translate(639 1489)"
          />
          <path
            d="M27.908 15.087l-8.785 12.27c-.24.279-.54.339-.702.35-.165.01-.483-.01-.763-.273l-5.677-5.54c-.705-.688-1.833-.674-2.52.03-.688.705-.674 1.833.03 2.52l5.687 5.55.016.016c.853.812 1.991 1.263 3.162 1.263.1 0 .202-.003.302-.01 1.276-.085 2.467-.705 3.267-1.702.021-.026.041-.052.06-.079l8.82-12.32c.574-.8.39-1.914-.41-2.487-.801-.573-1.915-.389-2.487.412z"
            transform="translate(639 1489)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
